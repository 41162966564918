<template>
  <AppLayoutUserPublicMain>
    <template v-slot:header>
      <AppHeader :countFavorites="favoritesAmount.count" />
    </template>
    <template v-slot:aside>
      <div class="main-aside">
        <div class="main-aside-filter">
          <AppCityNavigation />
          <AppMainFilter />
        </div>
        <div class="main-aside-massage-types">
          <h3 class="title">Виды массажа</h3>
          <ul class="list"><li class="list-item" v-for="(massageType, index) in massageTypes" :key="index">{{ massageType }}</li></ul>
        </div>
        <div class="main-aside-add-massage-types">
          <h3 class="title">Дополнительные виды массажа</h3>
          <ul class="list"><li class="list-item" v-for="(massageType, index) in additionalMassageTypes" :key="index">{{ massageType }}</li></ul>
        </div>
      </div>
    </template>
    <template v-slot:main>
      <div class="title">Избранное</div>
      <AppSeacringForm @search="searchForMassage($event)" :loading="searchLoading" />
      <div class="massagists-cards" v-if="massagists.massagists && !massagists.loading">
        <!-- {{ massagists.massagists }} -->
        <AppMassagistCardMain v-for="(massagist, index) in massagists.massagists" :key="index" :massagist="massagist" @action="goToPage(massagist.id)" @like="addToFavorite($event)" />
      </div>
      <div class="" v-if="!massagists.massagists && !massagists.loading">Вы не добавили никого в избранное</div>
    </template>
    <template v-slot:section>
    </template>
    <template v-slot:footer>
      <AppNavigationMobile />
      <AppFooter />
    </template>
  </AppLayoutUserPublicMain>
</template>



<script>
import AppLayoutUserPublicMain from "@/layouts/user/public/app-layout-public-main-user.vue";
import AppHeader from "@/sections/app-header.vue";
import AppNavigationMobile from "@/sections/app-navigation-mobile.vue";
import AppSeacringForm from "@/components/forms/app-searching-form.vue";
import AppMassagistCardMain from "@/components/cards/app-massagist-main-card.vue";
import AppCityNavigation from "@/components/navigation/app-city-navigation.vue";
import AppMainFilter from "@/components/filters/app-main-filter.vue";
import AppFooter from "@/sections/app-footer.vue";
import massagistsAPI from "@/api/massagist.js";
// import massagists from "@/database/massagists.js";
import massageTypesList from "@/database/massageTypes.js";
import additionalMassageTypesList from "@/database/addintionalMassageTypes.js";
import favorites from "@/logic/favorites.js";

import { ref } from "vue";
import {useRouter} from "vue-router";
import { reactive } from 'vue';
import { onMounted } from 'vue';
export default {

  name: "Main",
  components: {
    AppLayoutUserPublicMain,
    AppHeader,
    AppNavigationMobile,
    AppSeacringForm,
    AppMassagistCardMain,
    AppCityNavigation,
    AppMainFilter,
    AppFooter
  },
  setup(){
    const router = useRouter();
    
    const massagists = reactive({
      loading: true,
      massagists: null,
      getMassagistsByIDs: async () => {
        const massagistsIDs = JSON.parse(localStorage.getItem('favorites'));
        if(massagistsIDs){
          await massagistsAPI.getMassagistsByIDs({ massagists_ids: massagistsIDs })
              .then(response => {
                massagists.massagists = response.data;
              })
            .catch(err => console.log(err))
          await massagistsAPI.getPhotosForMultipleMassagists({ massagists_ids: massagistsIDs})
              .then(response => {
                massagists.massagists.forEach(massagist => {
                  massagist.photos = []
                  response.data.forEach(photo => {
                    if(massagist.id === photo.massagist_id){
                      massagist.photos.push(photo)
                    }
                  })
                })
              })
              .catch(err => console.log(err))
          
          massagists.massagists.forEach(massagist => massagist.inFavorite = 1)
          
          massagists.loading = false;
          
        } else {
          massagists.loading = false;
        }

    }
  });

    const favoritesAmount = reactive({
      count: 0
    });

    const countFavorites = () => {
      let count;
      const favorites = JSON.parse(localStorage.getItem('favorites'));
      favorites ? count = favorites.length : count = 0;

      favoritesAmount.count = count;
    }

    const addToFavorite = (id) => {
      favorites.addToFavorites(id, massagists.massagists);
      massagists.loading = true;
      massagists.massagists = []
      massagists.getMassagistsByIDs();
      countFavorites();
    }

    const massageTypes = massageTypesList;
    const additionalMassageTypes = additionalMassageTypesList;
    const searchForMassage = (data) => {
      console.log(data);
      searchLoading.value = true;
      setTimeout(() => {
        searchLoading.value = false;
      }, 4000)
    };
    const searchLoading = ref(false);

    const goToPage = (id) => {
      router.push(`/massagist/${id}`)
    }

    onMounted(massagists.getMassagistsByIDs, countFavorites())
    
    return {
      searchForMassage,
      searchLoading,
      massagists,
      massageTypes,
      additionalMassageTypes,
      goToPage,
      favoritesAmount,
      addToFavorite
    }
  }
};
</script>

<style lang="scss" scoped>
  .title{
    font-size: 1.5em;
    margin: 1em auto;
    text-align: center;
    color: $black;
    font-weight: 700;
    margin-top: -0.5em;
    .title-accent{
      color: $accent-dark;
    }
  }
  .main-aside{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    .main-aside-filter{
      height: 700px;
    }
    .main-aside-massage-types{
      padding: 1em 0;
      margin: 0 0 1em 0;
      text-align: left;
      width: 70%;
      .list{
        list-style: none;
        .list-item{
          font-size: 1.1em;
          font-weight: 700;
          color: $black;
          cursor: pointer;
          &:hover{
            color: $accent-dark;
          }
        }
      }

    }
    .main-aside-add-massage-types{
      padding: 1em 0;
      margin: 0 0 1em 0;
      text-align: left;
      width: 70%;
          .list{
        list-style: none;
        .list-item{
          font-size: 1.1em;
          font-weight: 700;
          color: $black;
          cursor: pointer;
          &:hover{
            color: $accent-dark;
          }
        }
      }
    }
  }

  .massagists-cards{
    width: 100%;
    padding: 2em 0;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }

  @media screen and(min-width: 375px){
    .massagists-cards{
      justify-content: center;
  }
  }
</style>