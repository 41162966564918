export default [
    "Антицеллюлитный",
    "Аппаратный",
    "Баночный",
    "Восточный",
    "Классический",
    "Косметический",
    "Лечебный",
    "Массаж головы",
    "Массаж груди",
    "Массаж лица",
    "Массаж ног",
    "Массаж рук",
    "Массаж спины",
    "Расслабляющий",
    "Реанимационный",
    "Спортивный",
    "Точечный",
    "Эротический",
]