/* eslint-disable */

export default [
    "Аква-пенный",
    "Боди массаж",
    "Гинекологический",
    "Древнеегипетский",
    "Колон-массаж",
    "Коррекция фигуры",
    "Криомассаж",
    "В четыре руки",
    "Массаж ложками",
    "Массаж ногами",
    "Массаж простаты",
    "Массаж стоп",
    "Обертывание",
    "Тайский",
    "Тантрический",
    "Урологический",
    "Шведский",
    "Японский"
]
